<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppIcon from "@/Components/Shared/icon/AppIcon.vue";
export type TextAs = "div" | "p" | "span";
export type TextSize = "xxsmall" | "xsmall" | "small" | "regular" | "large" | "xlarge";
export type TextColor =
    | "primary"
    | "current"
    | "red"
    | "white"
    | "grayLight"
    | "secondary";
export type TextWeight = "medium" | "semibold" | "normal" | "bold";

type Props = {
    as?: TextAs;
    size?: TextSize;
    weight?: TextWeight;
    color?: TextColor;
    icon?: string;
    iconSize?: string;
    iconColor?: string;
    hyphenate?: boolean;
};

const {
    as = "p",
    size = "regular",
    weight = "normal",
    color = "gray",
    icon,
    hyphenate = false,
} = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "text-slate-700 font-normal selection:bg-theme/10 selection:text-theme hyphens-auto",
    variants: {
        size: {
            xxsmall: "text-[12px]",
            xsmall: "text-[13px]",
            small: "text-sm",
            regular: "text-base",
            large: "text-lg",
            xlarge: "text-2xl",
        },
        color: {
            primary: "text-primary",
            current: "text-current",
            red: "text-red-600",
            white: "text-white",
            grayLight: "text-slate-500",
            secondary: "text-purple-700",
        },
        weight: {
            medium: "font-medium",
            semibold: "font-semibold",
            normal: "font-normal",
            bold: "font-bold",
        },
    },
};
const { aClass } = installAntlerComponent(
    "text",
    { weight, color, size },
    classes,
);
</script>

<template>
    <component
        :is="as"
        :class="
            aClass({
                'inline-flex items-center gap-2': icon,
                'hyphens-auto': hyphenate,
            })
        "
    >
        <AppIcon
            v-if="icon"
            :name="icon"
            :size="iconSize"
            :color="iconColor"
        />

        <slot />
    </component>
</template>
